<template>
    <div>
        <div class="flex-center" v-loading="loading" element-loading-text="正在为您激活账号...">
            <div class="center-title"><i :class="isactive ? 'el-icon-success' : 'el-icon-error'" class="activeIcon" ></i>{{ msg }}</div>
            <div v-if="isactive" style="color:#F56C6C">{{ countDown }}</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import api from "@/api/api";

export default {
    data() {
        return {
            msg:'',
            isactive:false,
            loading:true,
            countDown:5
        }
    },
    created() {
        this.activatePaas()
    },
    methods: {
        activatePaas() {
            let email = this.$route.query.email
            if(email){
                api.activatePaas(email).then(res => {
                    if (res.code == 200 && res.data) {
                        this.isactive =  true
                        this.msg = '账号激活成功，正在为您跳转paas平台'
                        this.startCountDown()
                        window.setTimeout(() => {
                            window.location.href = 'https://paas.extrotec.com:30443'
                        },5000)
                    } else {
                        this.isactive =  false
                        this.msg = res.msg
                    }
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
            }
            
        },
        startCountDown(){
            window.setTimeout(() => {
                if(this.countDown > 0){
                    this.countDown -= 1
                    this.startCountDown()
                }
            },1000)
        }
    }
}
</script>
<style>
.flex-center{
    width: 12rem;
    height: 6rem;
    margin: 1rem auto;
    border-radius: .2rem;
    background-color: #f1f2f3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.el-icon-success{
    color: #67C23A;
    
}
.el-icon-error{
    color:#F56C6C
}
.activeIcon{
    font-size: .6rem;
    position: relative;
    top:.1rem;
    right: 12px;
}
.center-title{
    line-height: .8rem;
    padding: .2rem;
    font-size: .3rem;
    font-weight: 600;
    color: #333;

}
</style>